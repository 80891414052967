// As you can see in App.js there are two Components for 2 types of paths:
//  1) path={`${match.url}/page/:id`} => Page is shown (meta data is done by helmet)
//  2) path="/:lang/products/:id" => Product is shown (helmet for the meta data is in there too)

// The Page component renders a fragment in which 2 things are rendered:
//   1) The dynamic part of the page, which is gotten from the pages node in the state and whatever location
//      you're currently on (which it gets from the payload from the locationReducer which is tied into RFR).
//   2) The pre-written component if there is one, which it checks in an object with all the pre-written
//      components we have in the entire app.

// Default react stuff
import React, { Fragment } from 'react'
import { connect } from 'react-redux' // To access stuff in redux-store and to dispatch action creators

// Third party Components
import Button from '@material-ui/core/Button' // To have the back button
import { Link } from 'react-router-dom'

import { withRouter } from 'react-router-dom' // To have access to match, so we can get stuff from path

import Home from '../Home/Home'
import Totaalbeeld from '../Totaalbeeld/Totaalbeeld'
import PageNotFound from '../PageNotFound/PageNotFound'

// The object with all of the aformentioned pre-written components, which are imported
const staticContent = {
  home: Home,
  totaalbeeld: Totaalbeeld
}

// - pages come from the redux-store
// - match comes from react-router-dom (which is connected with withRouter at the bottom too) and is used
//   to get the id parameter from the url so the proper page can be returned.
const Page = ({ pages, match  }) => {

  // Here we check whether the page from the payload is in the object with pre-written components above.
  // The result is in the third line of the fragment, if it's in there, include the component.
  const page = match.params.page || 'home' // If no page can be gotten from the payload (upon initial load), 'home' is used.
  const StaticContent = staticContent[page]
  if(!pages) return false
  const currentPage = pages[page.toLowerCase()]

  if(!currentPage) {
    return(
      <>
        <PageNotFound />
      </>
    )
  } else {
    return (
      <>
        <h1>{ pages[page].title }</h1>
        <span dangerouslySetInnerHTML={{__html: pages[page].content}}></span>
        {StaticContent && <StaticContent />}
      </>
    )
  }

}

const mapState = ({ pages }) => ({ pages })
export default connect(mapState)(withRouter(Page))
