// Default react stuff
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'


// https://www.pexels.com/nl-nl/zoeken/total%20picture/

import fire from '../../img/fire.jpg'
import privacy from '../../img/privacy.jpg'
import artikels from '../../img/artikels.jpg'
import electrosmog from '../../img/electrosmog.jpg'
import inspiratie from '../../img/inspiratie.jpg'
import straling from '../../img/straling.jpg'
import totaalbeeld from '../../img/total_picture.jpg'
import wet from '../../img/wet.jpg'
import fraude from '../../img/fraude.jpg'

// Styling
import './home.css'

const Home = ({pages}) => {

  return(
    <>

      <Grid container className='menuGrid' spacing={2}>

      <Grid key='fraude' item md={4} sm={6} xs={12}>
          <Link to ='/fraude'><img className='menuItem' src={fraude} /></Link>
        </Grid>

        <Grid key='brandgevaar' item md={4} sm={6} xs={12}>
          <Link to ='/brandgevaar'><img className='menuItem' src={fire} /></Link>
        </Grid>

        <Grid key='privacy' item md={4} sm={6} xs={12}>
          <Link to ='/privacy'><img className='menuItem' src={privacy} /></Link>
        </Grid>

        <Grid key='artikels' item md={4} sm={6} xs={12}>
          <Link to ='/artikels'><img className='menuItem' src={artikels} /></Link>
        </Grid>

        <Grid key='electrosmog' item md={4} sm={6} xs={12}>
          <Link to ='/electrosmog'><img className='menuItem' src={electrosmog} /></Link>
        </Grid>

        <Grid key='inspiratie' item md={4} sm={6} xs={12}>
          <Link to ='/inspiratie'><img className='menuItem' src={inspiratie} /></Link>
        </Grid>

        <Grid key='straling' item md={4} sm={6} xs={12}>
          <Link to ='/straling'><img className='menuItem' src={straling} /></Link>
        </Grid>

        <Grid key='wet' item md={4} sm={6} xs={12}>
          <Link to ='/wet'><img className='menuItem' src={wet} /></Link>
        </Grid>
        
        <Grid key='totaalbeeld' item md={4} sm={6} xs={12}>
          <Link to ='/totaalbeeld'><img className='menuItem' src={totaalbeeld} /></Link>
        </Grid>

      </Grid>
    </>
  )
}

const mapState = ({pages}) => ({pages})
export default connect(mapState)(Home)
