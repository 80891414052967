// Default react stuff
import React, { useState } from 'react'

// This party libraries
import HomeIcon from '@mui/icons-material/Home'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'

// Styling
import './home.css'

const HomeButton = () => {

  return(
    <>
        <div className='homeBtn'>

            <a className='home' href="/">
                <HomeIcon />
            </a>

        </div>
 
    </>
  )
}


export default HomeButton
