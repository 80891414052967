// Default react libraries
import React from 'react'

// Third party libraries
import Grid from '@material-ui/core/Grid'

import './banner.css'

import banner from '../../img/banner.jpg'

const Banner = () => {

  return (
    <Grid container direction='row' alignItems='center'>
      <img src={banner} id='bannerPicture' alt='banner' />
    </Grid>
  )

}

export default Banner
