// Default libraries of React
import React, {useEffect} from 'react'

// Third party libraries
// import { Route, withRouter } from 'react-router-dom'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import ScrollUpButton from 'react-scroll-up-button'

// Custom components
import Banner from './components/Banner/Banner'
import Footer from './components/Footer/Footer'
import Page from './components/Page/Page'
import HomeButton from './components/Home/HomeButton'

import { pagesRequest } from './actions/pages'

// Styling
import './App.css'

const App = ({dispatch}) => {

  useEffect(() => {
    dispatch(pagesRequest())
  }, [dispatch])

  return (
    <>
      <Grid container direction="column" alignItems="center" className="App" wrap="nowrap" spacing={0}>
        <Grid item id="banner" xl={10} lg={10} md={11} sm={12} xs={12}>
          <Banner />
        </Grid>
        <Grid item id="mainContainer" xl={10} lg={10} md={11} sm={12} xs={12}>
          <Switch>
            <Redirect exact from="/" to="/home" />
            <Route path="/:page" component={Page} />
          </Switch>
        </Grid>
        <Grid item id="footer" xl={10} lg={10} md={11} sm={12} xs={12}>
          <Footer />
        </Grid>
      </Grid>
      <ScrollUpButton />
      <HomeButton />
    </>
  )

}

export default connect()(App)