import { firebase } from '../firebase'
import { PAGES_SUCCESS, PAGE_ADD, PAGE_ADD_ERROR, PAGE_MODIFY, PAGE_MODIFY_ERROR } from './types'

export const pagesRequest = () => {
  return (dispatch) => {
    firebase
      .database()
      .ref('pages')
      .once('value', (snapshot) => {
        dispatch({ type: PAGES_SUCCESS, payload: snapshot.val() })
      })
  }
}