// Default react libraries
import React from 'react'

// Custom Styling
import './footer.css'

const Footer = () => {

  return(
    <>
      <span id="footerCopyright">2023 - Bewust over technologie</span>
    </>
  )

}

export default Footer
