import firebase from 'firebase/compat/app'
import 'firebase/compat/database' // to get stuff from the database
import 'firebase/compat/auth'     // to be able to use the authorization stuff
import 'firebase/compat/storage'  // to upload and delete an image

// Initialize Firebase
const config = {
    apiKey: "AIzaSyAQr7lyFcSKS0F4m8aSvyVmVEDw6ifS4tI",
    authDomain: "digitale-meter-9a400.firebaseapp.com",
    databaseURL: "https://digitale-meter-9a400-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "digitale-meter-9a400",
    storageBucket: "digitale-meter-9a400.appspot.com",
    messagingSenderId: "287413584433",
    appId: "1:287413584433:web:12c79de17f9b0890694b10"
}

// this used to be const, but that doesn't make it out of the try to export
let app = undefined

try {
  app = firebase.initializeApp(config)
} catch (err) {
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack)
  }
}

// For uploading & deleting images
const storage = app.storage()

export { firebase, app, storage }
