// Default react stuff
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'

import { Link } from 'react-router-dom'

// Styling
import './totaalbeeld.css'

const Totaalbeeld = () => {

  return(
    <>

      <br />

      <p>Kies hieronder het kadertje met thema:</p>

      <Grid container className='menuGridTotaalBeeld' spacing={4}>

        <Grid key='fraude' item md={5} sm={10} xs={10} className='totaalBeeldItem'>
          <Link to ='/technologie_in_medische_en_militaire_wereld' className='noUnderline'>Technologie in medische & militaire wereld bijzondere aandacht voor hersenmanipulatie</Link>
        </Grid>

        <Grid key='brandgevaar' item md={5} sm={10} xs={10} className='totaalBeeldItem'>
          <Link to ='/nederlandse_uitleg_over_elektromagnetische_straling_en_5g' className='noUnderline'>Nederlandse uitleg over elektromagnetische straling en 5G</Link>
        </Grid>

        <Grid key='privacy' item md={5} sm={10} xs={10} className='totaalBeeldItem'>
          <Link to ='/wie_bezit_de_technologie' className='noUnderline'>Wie bezit de technologie?</Link>
        </Grid>

        <Grid key='artikels' item md={5} sm={10} xs={10} className='totaalBeeldItem'>
          <Link to ='/slimme_led_verlichtingspalen' className='noUnderline'>Slimme LED verlichtingspalen</Link>
        </Grid>

        <Grid key='electrosmog' item md={5} sm={10} xs={10} className='totaalBeeldItem'>
          <Link to ='/klimaatverandering_bewust_of_onbewust_geactiveerd' className='noUnderline'>Klimaatverandering: bewust of onbewust geactiveerd?</Link>
        </Grid>

        <Grid key='inspiratie' item md={5} sm={10} xs={10} className='totaalBeeldItem'>
          <Link to ='/wat_is_geo_engineering' className='noUnderline'>Wat is Geo Engineering?</Link>
        </Grid>

        <Grid key='straling' item md={5} sm={10} xs={10} className='totaalBeeldItem'>
          <Link to ='/groene_technologie_vaak_vervuilender_dan_oude_technologie' className='noUnderline'>'Groene' technologie vaak vervuilender dan 'oude' technologie</Link>
        </Grid>

        <Grid key='wet' item md={5} sm={10} xs={10} className='totaalBeeldItem'>
          <Link to ='/oplossing_om_natuur_en_zo_klimaat_in_evenwicht_te_brengen' className='noUnderline'>Oplossingen om natuur en zo klimaat in evenwicht te brengen</Link>
        </Grid>
        
        <Grid key='totaalbeeld' item md={5} sm={10} xs={10} className='totaalBeeldItem'>
          <Link to ='/mensen_die_link_leggen_tussen_virus_en_5g' className='noUnderline'>Mensen die link leggen tussen virus en 5G</Link>
        </Grid>

        <Grid key='totaalbeeld' item md={5} sm={10} xs={10} className='totaalBeeldItem'>
          <Link to ='/speciaal_hoekje' className='noUnderline'>Speciaal hoekje</Link>
        </Grid>

      </Grid>
    </>
  )
}


export default Totaalbeeld
